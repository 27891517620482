import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'src/components/layout';
import Landing from 'src/components/landing';

export default function HomePage({ data }) {
  return (
    <Layout>
      <Landing
        integrations={data.site.siteMetadata.integrations}
        integrationsLogos={data.integrationsLogos.edges}
        heroVideos={data.heroVideos.edges}
        stepsListVideos={data.stepsListVideos.edges}
        brandsLogos={data.brandsLogos.edges}
      />
    </Layout>
  );
}

export const query = graphql`
  query LandingQuery {
    site {
      siteMetadata {
        integrations {
          name
          url
        }
      }
    }

    allAppcastRelease {
      edges {
        node {
          id
          title
          date
          version
          minimumSystemVersion
          downloadUrl
        }
      }
    }

    integrationsLogos: allFile(filter: { sourceInstanceName: { eq: "integrations-logos" } }) {
      edges {
        node {
          id
          name

          childImageSharp {
            gatsbyImageData(quality: 80, height: 32, placeholder: BLURRED)
          }
        }
      }
    }

    brandsLogos: allFile(filter: { sourceInstanceName: { eq: "brands-logos" } }) {
      edges {
        node {
          id
          name

          childImageSharp {
            gatsbyImageData(quality: 80, height: 24, placeholder: TRACED_SVG)
          }
        }
      }
    }

    heroVideos: allFile(filter: { sourceInstanceName: { eq: "video-hero" } }) {
      edges {
        node {
          id
          name

          mp4: videoH264 {
            path
          }

          vp9: videoVP9 {
            path
          }

          posters: videoScreenshots(timestamps: ["1"]) {
            publicURL
          }
        }
      }
    }

    stepsListVideos: allFile(filter: { sourceInstanceName: { eq: "video-steps-list" } }) {
      edges {
        node {
          id
          name

          mp4: videoH264(maxWidth: 624) {
            path
          }

          mp4Retina: videoH264(maxWidth: 936) {
            path
          }

          vp9: videoVP9(maxWidth: 624) {
            path
          }

          vp9Retina: videoVP9(maxWidth: 936) {
            path
          }

          posters: videoScreenshots(timestamps: ["1"], width: 624) {
            publicURL
          }
        }
      }
    }
  }
`;
