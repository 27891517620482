import React, { useState, useEffect, useRef } from 'react';

export default function ScrollVideo(props) {
  const ref = useRef(null);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);

  useEffect(() => {
    function onScroll() {
      const { bottom } = ref.current.getBoundingClientRect();
      const isVideoWithinViewport = bottom < window.innerHeight;

      // Start playing video after we scroll past it for the first time.
      // Ignore any scrolling afterwards.
      if (isVideoWithinViewport && !hasStartedPlaying) {
        ref.current.play();
        setHasStartedPlaying(true);
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return <video muted loop ref={ref} {...props} />;
}
