import React, { useState, useEffect, createRef } from 'react';
import clsx from 'clsx';

import { STEPS } from 'src/data/landing';
import usePixelRatio from 'src/hooks/use-pixel-ratio';

export default function StepsList({ integrations, integrationsLogos, videos }) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false);
  const pixelRatio = usePixelRatio();
  const isPlayingRetina = pixelRatio > 1;

  const steps = STEPS({ integrations, integrationsLogos, videos });
  const videoRefs = [...new Array(steps.length).keys()].map((_) => createRef(null));

  useEffect(() => {
    function onScroll() {
      const rects = videoRefs.map((ref) => ref.current.getBoundingClientRect());
      const newIndex = rects.findIndex((ref) => ref.top > 0);

      const isFirstVideoWithinViewport = rects[0].bottom < window.innerHeight;

      // Start playing the first video after we scroll past it for the first time.
      if (isFirstVideoWithinViewport && !hasStartedPlaying) {
        videoRefs[0].current.play();
        setHasStartedPlaying(true);
      }

      if (newIndex < 0 || newIndex === activeStepIndex) {
        return;
      }

      const oldVideoEl = videoRefs[activeStepIndex].current;
      const newVideoEl = videoRefs[newIndex].current;

      if (oldVideoEl) {
        oldVideoEl.pause();
      }

      if (newVideoEl) {
        newVideoEl.currentTime = 0;
        newVideoEl.play();
      }

      setActiveStepIndex(newIndex);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <ul className="flex flex-col items-center mt-6 space-y-16 md:mt-20 md:space-y-16">
      {steps.map((step, index) => (
        <li
          key={index}
          className={clsx(
            'flex flex-col items-start transform space-y-4 transition duration-200 md:flex-row-reverse md:space-y-0'
          )}
        >
          {/* Wrapping img fixes image aspect ratio */}
          <figure
            className={clsx(
              'h-auto transform transition duration-200',
              index !== activeStepIndex && 'scale-95 opacity-50'
            )}
          >
            <video
              loop
              muted
              className="min-w-0 min-h-0 rounded-lg shadow-md"
              ref={videoRefs[index]}
              poster={step.video.posters[0].publicURL}
            >
              <source
                src={isPlayingRetina ? step.video.vp9Retina.path : step.video.vp9.path}
                type="video/webm"
              />
              <source
                src={isPlayingRetina ? step.video.mp4Retina.path : step.video.mp4.path}
                type="video/mp4"
              />
            </video>
          </figure>

          <div className="flex md:flex-row-reverse md:mr-16 md:pt-8 md:w-full md:max-w-13xl">
            <div className="md:font-base flex flex-col flex-grow items-start text-sm text-gray-800 space-y-4 md:ml-6 md:text-lg">
              <h3 className="text-gray-900 text-lg font-bold md:text-2xl">{step.title}</h3>
              {step.body}
            </div>

            <div className="text-md hidden flex-shrink-0 w-10 h-10 text-center text-yellow-500 font-bold leading-10 bg-yellow-50 rounded-full select-none md:block">
              {index + 1}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
