import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { forSize } from 'src/style-utils';

const Container = styled.a`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  margin: 0 12px 8px 0;
  align-items: center;
  justify-content: center;

  ${forSize.tabletPortraitUp`
    width: 32px;
    height: 32px;
    margin: 0 20px 24px 0;
  `}

  &:after {
    content: '${(props) => props.name}';
    position: absolute;
    top: calc(100% - 2px);

    display: block;

    opacity: 0;

    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;

    transition: 0.15s ease-out;

    ${forSize.tabletPortraitUp`
      font-size: 13px;
    `}
  }

  &:hover:after {
    top: calc(100% + 2px);
    opacity: 1;
  }
`;

const Logo = styled(GatsbyImage)`
  display: block;
  width: 100%;
  max-height: 100%;
`;

export default function Item({ url, name, image }) {
  return (
    <Container href={url} name={name}>
      <Logo image={image} alt={name} />
    </Container>
  );
}
