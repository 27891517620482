import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import Item from './item';

export default function IntegrationsList({ integrations, logos }) {
  return (
    <div className="flex flex-row flex-wrap mt-3">
      {integrations
        .map((i) => [i, logos.map((edge) => edge.node).find((node) => node.name === i.name)])
        .map(([{ name, url }, logo]) => (
          <Item key={name} url={url} name={name} image={getImage(logo)} />
        ))}
    </div>
  );
}
