import React, { useMemo } from 'react';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import tailwindConfig from 'src/../tailwind.config.js';

import SignupForm from 'src/components/signup-form';
import StepsList from './steps-list';
import ScrollVideo from './scroll-video';
import FAQItem from './faq-item';
import useViewport from '../../hooks/use-viewport';

import { HERO, LOGOS, FAQ, FEATURES } from 'src/data/landing';

const MEDIUM_BREAKPOINT = tailwindConfig.theme.screens.md;

export default function Landing({
  integrations,
  integrationsLogos,
  heroVideos,
  stepsListVideos,
  brandsLogos,
}) {
  const { width } = useViewport();
  const renderTwoColumnFAQ = width >= parseInt(MEDIUM_BREAKPOINT, 10) || width === 0;

  const alternatingFAQItems = useMemo(() => {
    const items = [];

    FAQ.left.forEach((leftColumnItem, index) => {
      const rightColumnItem = FAQ.right[index];
      leftColumnItem && items.push(leftColumnItem);
      rightColumnItem && items.push(rightColumnItem);
    });

    return items;
  }, []);

  return (
    <div className="relative flex flex-col items-center">
      <div className="relative w-full z-10 pb-8 px-6 sm:px-10 max-w-screen-xl">
        <main className="max-w-full flex flex-col items-center mt-8 space-y-12 md:space-y-32">
          <div className="max-w-full flex flex-col items-center space-y-12 md:space-y-20">
            <section className="max-w-full flex flex-col items-center space-y-4">
              <h1 className="text-center text-gray-900 whitespace-pre-line font-display font-bold text-2xl leading-8 md:text-4xl md:leading-tight">
                {HERO.title}
              </h1>

              <div className="text-sm text-center text-gray-800 md:text-base">{HERO.body}</div>

              <SignupForm />

              <p className="text-center text-gray-700 text-opacity-60 text-xs md:text-sm">
                Try for free while in beta. macOS 10.15+
              </p>
            </section>

            <section className="space-y-4 md:space-y-10" style={{ maxWidth: '745px' }}>
              <figure className="flex flex-col items-center space-y-4 md:flex-col-reverse md:space-y-reverse">
                <figcaption className="text-sm text-center text-gray-800 md:text-base">
                  Easily accessible window with&nbsp;all&nbsp;your&nbsp;tasks...
                </figcaption>
                {/* HACK: `transform-gpu` fixes otherwise ignored border-radius on Safari. */}
                <StaticImage
                  className="min-w-0 max-h-screen min-h-0 rounded-lg transform-gpu"
                  src="../../assets/screenshot-1.png"
                  alt="Screenshot of MonoFocus app"
                  objectPosition="top center"
                  quality={80}
                />
              </figure>

              <figure className="flex flex-col items-center space-y-4 md:flex-col-reverse md:space-y-reverse">
                <figcaption className="text-sm text-center text-gray-800 md:text-base">
                  ...plus a pop-up reminder for when you get distracted!
                </figcaption>
                <ScrollVideo
                  className="min-w-0 min-h-0 rounded-lg"
                  poster={heroVideos.find((v) => v.node.name === '1').node.posters[0].publicURL}
                >
                  <source
                    src={heroVideos.find((v) => v.node.name === '1').node.vp9.path}
                    type="video/webm"
                  />
                  <source
                    src={heroVideos.find((v) => v.node.name === '1').node.mp4.path}
                    type="video/mp4"
                  />
                </ScrollVideo>
              </figure>
            </section>
          </div>

          <section>
            <div
              className="w-full h-px"
              style={{
                background:
                  'radial-gradient(50% 50% at 50% 50%, #E7E6E3 88.54%, rgba(231, 230, 227, 0) 100%)',
              }}
            ></div>
            <div className="py-8">
              <h4 className="text-center text-gray-600 text-xs md:text-sm">
                Helping people stay focused at:
              </h4>
              <ul className="flex flex-row justify-around mb-2 mt-4 space-x-6 md:justify-center md:mt-6 md:space-x-10">
                {LOGOS.map((logo) => (
                  <li key={logo.title}>
                    <GatsbyImage
                      className="opacity-50 hover:opacity-80 transition-all"
                      image={getImage(
                        brandsLogos
                          .map((edge) => edge.node)
                          .find((node) => node.name === logo.imageName)
                      )}
                      alt={logo.title}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="w-full h-px"
              style={{
                background:
                  'radial-gradient(50% 50% at 50% 50%, #E7E6E3 88.54%, rgba(231, 230, 227, 0) 100%)',
              }}
            ></div>
          </section>

          <section className="flex flex-col items-center">
            <h2 className="text-2xl leading-8 text-center text-gray-900 font-display font-bold md:text-3xl md:leading-tight">
              Productivity apps are broken.
            </h2>
            <p className="mt-4 text-sm text-center text-gray-800 md:text-base">
              Every tool is trying to help you remember what to do,
              <br />
              <b>
                <span className="whitespace-nowrap">but none helps you actually get it done.</span>
              </b>
            </p>

            <p className="inline-block mt-6 px-2 py-1 text-center text-yellow-500 whitespace-nowrap text-sm font-bold bg-yellow-50 rounded-full md:px-4 md:py-2 md:text-base">
              Here’s why MonoFocus is different:
            </p>

            <StepsList
              integrations={integrations}
              integrationsLogos={integrationsLogos}
              videos={stepsListVideos}
            />
          </section>

          <section className="pt-8 pb-4">
            <div className="pb-2 rounded-lg shadow-xl overflow-hidden md:pb-0">
              <ul className="grid gap-0.5 grid-cols-1 bg-gray-100 md:grid-cols-2">
                {FEATURES.map((feature) => (
                  <li
                    key={feature.title}
                    className="px-5 py-4 text-sm bg-white space-y-2 md:px-7 md:py-8 md:text-base"
                  >
                    <h3 className="text-gray-900 text-lg font-bold md:text-2xl">{feature.title}</h3>
                    {feature.body}
                  </li>
                ))}
              </ul>
            </div>
          </section>

          <section id="faq" className="pt-4">
            <h2 className="text-2xl leading-8 text-center text-gray-900 font-display font-bold mb-14 md:mb-20 md:text-3xl md:leading-tight">
              Frequently Asked Questions
            </h2>
            <div className="flex">
              {renderTwoColumnFAQ ? (
                <>
                  <ul className="flex-1 space-y-10 px-5 md:px-8 md:space-y-14">
                    {FAQ.left.map((item) => (
                      <FAQItem key={item.question} {...item} />
                    ))}
                  </ul>
                  <ul className="flex-1 space-y-10 px-5 md:px-8 md:space-y-14">
                    {FAQ.right.map((item) => (
                      <FAQItem key={item.question} {...item} />
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <ul className="flex-1 space-y-10 px-5 md:px-8 md:space-y-14">
                    {alternatingFAQItems.map((item) => (
                      <FAQItem key={item.question} {...item} />
                    ))}
                  </ul>
                </>
              )}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
