import React from 'react';

export default function FaqItem({ question, answer, link }) {
  const anchor = createAnchor(question);

  return (
    <li id={anchor} className="space-y-3 rounded-sm target:shadow-highlight">
      <h3 className="text-lg font-semibold text-gray-900 mb-2 md:mb-4 md:text-2xl">{question}</h3>
      {answer && (
        <div className="text-sm text-gray-800 space-y-2 md:space-y-3 md:text-base">{answer}</div>
      )}
      {link && (
        <div className="text-sm font-medium md:text-base">
          <a href={link.url}>{link.text}</a>
        </div>
      )}
    </li>
  );
}

// Delete question marks and replace other non-alphabetical characters with a dash
export function createAnchor(question) {
  return question.toLowerCase().replace(/\?/gi, '').replace(/[\W]+/gi, '-');
}
