import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import IntegrationsList from 'src/components/landing/integrations-list';

export const HERO = {
  title:
    'Finally focus on\u00A0your\u00A0work.\nBeat\u00A0distractions\u00A0and get\u00A0things\u00A0done.',
  body: (
    <>
      <p>
        See your current task in the always&#8209;visible&nbsp;menu&nbsp;bar&nbsp;item.
        <br />
        Get a&nbsp;reminder to&nbsp;get&nbsp;back&nbsp;on&nbsp;track when&nbsp;distracted.
      </p>
    </>
  ),
};

export const STEPS = ({ integrations, integrationsLogos, videos }) => [
  {
    video: videos.find((v) => v.node.name === 'step-1').node,
    title: 'You start with all your tasks already\u00A0in\u00A0one\u00A0place.',
    body: (
      <>
        <p>
          Have all your tasks scattered between multiple apps? No more juggling
          between&nbsp;ten&nbsp;different&nbsp;apps to decide what you should focus on first.
        </p>
        <p>
          In addition to its own local tasks, MonoFocus seamlessly syncs with
          tools&nbsp;you&nbsp;already&nbsp;use:
        </p>
        <IntegrationsList integrations={integrations} logos={integrationsLogos} />
      </>
    ),
  },
  {
    video: videos.find((v) => v.node.name === 'step-2').node,
    title: 'Stay focused on a single task.',
    body: (
      <>
        <p>
          Once you choose a task to work on, it's always in your menu bar, a glance away. So you can
          finally focus on one thing.
        </p>
        <p>
          Working on a small screen? Don’t worry, MonoFocus resizes the task title automatically to
          fit into the available space.
        </p>
      </>
    ),
  },
  {
    video: videos.find((v) => v.node.name === 'step-3').node,
    title: 'Got distracted? Get\u00A0back\u00A0on\u00A0track\u00A0right\u00A0away.',
    body: (
      <>
        <p>
          With our completely customizable, friendly pop-up, you stay on track and get things done
          without trouble.
        </p>
        <p>
          Get reminded every time you switch between apps, or just every thirty minutes. If that
          still doesn't help... have it visible all the time.
        </p>
      </>
    ),
  },
  {
    video: videos.find((v) => v.node.name === 'step-4').node,
    title: 'Done? Complete the task and start over.',
    body: (
      <>
        <p>
          Complete a task either directly from the app or use Quick Entry available under a global
          keyboard shortcut.
        </p>
        <p>
          Have a project synced with third-party integrations? Customize what to do after completing
          the task:
        </p>
        <ul className="pl-4 mt-4 list-disc space-y-3">
          <li>
            Move{' '}
            <TextPill
              iconAlt="Trello"
              icon={integrationsLogos
                .map((edge) => edge.node)
                .find((node) => node.name === 'Trello')}
              color={PILL_COLORS.trello}
            >
              Trello Card
            </TextPill>{' '}
            <span className="inline-block">
              to{' '}
              <TextPill
                iconAlt="Trello"
                icon={integrationsLogos
                  .map((edge) => edge.node)
                  .find((node) => node.name === 'Trello')}
                color={PILL_COLORS.trello}
              >
                “Done” column
              </TextPill>
            </span>
          </li>
          <li>
            Close{' '}
            <TextPill
              iconAlt="GitHub"
              icon={integrationsLogos
                .map((edge) => edge.node)
                .find((node) => node.name === 'GitHub')}
              color={PILL_COLORS.github}
            >
              GitHub Issue
            </TextPill>
          </li>
          <li>
            Move{' '}
            <TextPill
              iconAlt="GitHub"
              icon={integrationsLogos
                .map((edge) => edge.node)
                .find((node) => node.name === 'GitHub')}
              color={PILL_COLORS.github}
            >
              GitHub Card
            </TextPill>{' '}
            <span className="inline-block">
              to{' '}
              <TextPill
                iconAlt="GitHub"
                icon={integrationsLogos
                  .map((edge) => edge.node)
                  .find((node) => node.name === 'GitHub')}
                color={PILL_COLORS.github}
              >
                “In Review” column
              </TextPill>
            </span>
          </li>
          <li>
            Complete{' '}
            <TextPill
              iconAlt="Apple Reminders"
              icon={integrationsLogos
                .map((edge) => edge.node)
                .find((node) => node.name === 'Apple Reminders')}
              color={PILL_COLORS.reminders}
            >
              Reminders task
            </TextPill>
          </li>
          <li>And more...</li>
        </ul>
      </>
    ),
  },
];

const PILL_COLORS = {
  trello: 'rgba(0, 121, 191, 0.1)',
  github: 'rgba(0, 0, 0, 0.1)',
  reminders: 'rgba(0, 0, 0, 0.1)',
};

const TextPill = ({ iconAlt, icon, color, children, ...props }) => (
  <span className="inline-block md:mx-1 my-1">
    <span
      className="inline-flex items-center flex-row px-2 py-px border-2 rounded-full font-medium text-xs md:text-sm"
      style={{ borderColor: color }}
      {...props}
    >
      <GatsbyImage className="w-4 h-4 mr-1 md:mr-2" alt={iconAlt} image={getImage(icon)} />
      {children}
    </span>
  </span>
);

export const LOGOS = [
  {
    imageName: 'swing',
    title: 'SwingDev',
  },
  {
    imageName: 'uoa',
    title: 'University of Alberta',
  },
  {
    imageName: 'usf',
    title: 'University of San Francisco',
  },
];

export const FEATURES = [
  {
    title: 'Fast and efficient – made\u00A0for\u00A0Mac.',
    body: (
      <>
        <p>
          Written using native technologies, it integrates with your Mac perfectly. Don’t worry
          about memory usage or shortened battery life.
        </p>
      </>
    ),
  },
  {
    title: 'Privacy first.',
    body: (
      <>
        <p>
          <b>None of your tasks leaves your computer.</b> Both local and synced projects never touch
          our servers. Credentials necessary for third-party integrations are stored locally and
          encrypted.
        </p>
      </>
    ),
  },
  {
    title: 'Seamless two-way sync with\u00A0your\u00A0tools.',
    body: (
      <>
        <p>
          Already using another to-do app or collaboration software? Just connect it with MonoFocus
          to sync your tasks automatically. Complete a task in MonoFocus, and have it completed in
          your other tool automatically.
        </p>
      </>
    ),
  },
  {
    title: '...and much more.',
    body: (
      <>
        <p className="text-justify">
          <b>Quick Entry</b> · Full Text Search · <b>Synced projects filters</b> · Intelligent
          syncing of In Progress tasks · Open synced tasks with one click ·{' '}
          <b>Customizable hot-keys</b> · Task labels, due dates, priorities · Customizable mark as
          done behavior · <b>Inactivity notifications</b>
        </p>
      </>
    ),
  },
];

export const FAQ = {
  left: [
    {
      question: 'How do integrations work?',
      answer: (
        <>
          <p>If you already use another to-do apps then you can connect them with MonoFocus.</p>
          <p>
            This will enable two-way sync of your tasks. When you complete a task in MonoFocus, it
            will get completed in the other tool. When you add a task in MonoFocus, it will be added
            in the other tool. Everything happens automatically!
          </p>
        </>
      ),
    },
    {
      question: 'Do you plan on adding more integrations?',
      answer: (
        <>
          <p>
            We're mostly focusing on improving the overall app experience and existing features, but
            we'd love to hear your integrations suggestions!
          </p>
        </>
      ),
      link: {
        text: 'Feel free to suggest an integration →',
        url: 'https://airtable.com/shrK0L55pcQQLb8ep',
      },
    },
    {
      question: 'How can I suggest a new feature or improvement?',
      answer: (
        <>
          <p>
            We love hearing your feedback and ideas on how to improve the app! Email us at{' '}
            <a className="font-medium" href="mailto:hello@monofocus.app">
              hello@monofocus.app
            </a>{' '}
            anytime you want :)
          </p>
        </>
      ),
    },
    {
      question: 'Why do you request access to my source code when connecting with GitHub?',
      answer: (
        <>
          <p>
            Because we use OAuth authentication to integrate with GitHub’s API, we cannot request
            any more granular permissions than a general permission for accessing everything in your
            repository.
          </p>
          <p>
            The solution would be to migrate to authentication via GitHub Apps. However, it would
            require every repository owner you are collaborating with to install MonoFocus for you
            in their repository. Even if they do not use MonoFocus themselves.
          </p>
          <p>
            For now, we’ve decided to make this trade-off and continue using OAuth authentication
            since we don’t store any of your data (including authentication tokens) ourselves.
            Instead, all of your data is stored on your Mac and doesn’t leave it at any time. This
            way, you can safely integrate MonoFocus with GitHub without worrying about us accessing
            your private information or any data breaches of our servers.
          </p>
          <p>We consider providing both ways of connecting GitHub with MonoFocus. Stay tuned!</p>
        </>
      ),
    },
    {
      question: 'Having more questions?',
      link: {
        text: 'Shoot us an email → ',
        url: 'mailto:hello@monofocus.app',
      },
    },
  ],
  right: [
    {
      question: 'Will I have to pay for MonoFocus?',
      answer: (
        <>
          <p>
            You can use MonoFocus for free during our public beta. After the 1.0 release (planned
            for the second half of 2021), MonoFocus will become a paid app and we will continue to
            release new features and improvements.
          </p>
          <p>
            MonoFocus license will include a year's worth of updates.
            <br />
            After that, you can use your version of MonoFocus as long as you want. If you still want
            to receive new features and fixes, you can renew for another year's worth of updates.
          </p>
          <p className="text-sm italic">
            P.S. You'll receive a discount if you've used MonoFocus during public beta :)
          </p>
        </>
      ),
    },
    {
      question: 'Can I use MonoFocus without integrations?',
      answer: (
        <>
          <p>
            Yes! You can easily use MonoFocus as a standalone app. Just create a new local project
            in MonoFocus Preferences — now you can add, complete, archive, and reorder your local
            tasks!
          </p>
        </>
      ),
    },
    {
      question: 'How do you store my data?',
      answer: (
        <>
          <p>
            We take the privacy of your data seriously. Everything is fetched and stored locally.
            Your tasks will never leave your machine. The only data we collect are some anonymous
            statistics about the app usage. They help us improve the app and you can always disable
            them in MonoFocus Preferences.
          </p>
        </>
      ),
    },
    {
      question: 'Why don’t I receive any notifications?',
      answer: (
        <>
          <p>
            Please make sure you are allowing MonoFocus notifications in the System Preferences. To
            check that, visit System Preferences &gt; Notifications &gt; MonoFocus and check the
            settings. If the problem persists, please contact us at{' '}
            <a className="font-medium" href="mailto:hello@monofocus.app">
              hello@monofocus.app
            </a>
            .
          </p>
        </>
      ),
    },
    {
      question: 'Will you release a version for Windows or Linux?',
      answer: (
        <>
          <p>
            MonoFocus is a Mac native app, designed and written with macOS in mind. Because of that,
            we have no plans to launch MonoFocus for Windows or Linux.
          </p>
        </>
      ),
    },
  ],
};
